<script setup lang="ts">
import type { NuxtError } from '#app'

useSeoMeta({
	title: 'Página não encontrada',
	description: 'Infelizmente a página que você está procurando não foi encontrada.'
})

defineProps({
	error: {
		type: Object as PropType<NuxtError>,
		required: true
	}
})

useHead({
	htmlAttrs: {
		lang: 'pt-BR'
	}
})
</script>

<template>
	<div>
		<UMain>
			<UContainer>
				<UPage>
					<UPageError :error="error" />
				</UPage>
			</UContainer>
		</UMain>

		<UNotifications />
	</div>
</template>
