export default defineNuxtRouteMiddleware((to) => {
	const { hasNivelAcesso } = useAuthResolver()

	// Define route access levels
	const routeAccessLevels: Record<string, number[]> = {
		'/operacional': [ROLES.Diretoria, ROLES.Operacional, ROLES.Administrativo],
		'/rh': [ROLES.Diretoria, ROLES.Administrativo, ROLES.Financeiro],
		'/financeiro': [ROLES.Diretoria, ROLES.Administrativo, ROLES.Financeiro],
		'/afiliados': [ROLES.Diretoria],
		'/settings/members': [ROLES.Diretoria, ROLES.Administrativo],
		'/settings/notifications': [ROLES.Diretoria, ROLES.Administrativo, ROLES.Operacional],
		'/perfil': [ROLES.Diretoria, ROLES.Administrativo, ROLES.Operacional, ROLES.Financeiro, ROLES.Gerente, ROLES.Supervisor, ROLES.Promotor]
	}

	// Check if the route requires specific access
	const requiredAccess = Object.entries(routeAccessLevels).find(([path]) => to.path.startsWith(path))

	if (requiredAccess) {
		const [, accessLevels] = requiredAccess
		if (!hasNivelAcesso(...accessLevels)) {
			return navigateTo('/', { redirectCode: 301 })
		}
	}
})
