export const ROLES = {
	Diretoria: 1,
	Administrativo: 2,
	Financeiro: 3,
	Gerente: 4,
	Operacional: 5,
	Supervisor: 6,
	Promotor: 7,
	Afiliado: 8
}
