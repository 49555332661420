/* eslint-disable @typescript-eslint/naming-convention */
import type { MapLibreStyleSpecs } from '@unovis/ts'

export type MapPointDataRecord = {
  name: string;
  longitude: number;
  latitude: number;
  normal: number;
  blocked: number;
}

// Coordenadas de alguns estados do Brasil
export const data: MapPointDataRecord[] = [
  { latitude: -23.5505, longitude: -46.6333, name: 'São Paulo', normal: 948, blocked: 4 }, // SP
  { latitude: -22.9068, longitude: -43.1729, name: 'Rio de Janeiro', normal: 780, blocked: 5 }, // RJ
  { latitude: -19.9167, longitude: -43.9345, name: 'Minas Gerais', normal: 605, blocked: 2 }, // BH
  { latitude: -15.7975, longitude: -47.8919, name: 'Distrito Federal', normal: 446, blocked: 5 }, // DF
  { latitude: -25.4195, longitude: -49.2646, name: 'Paraná', normal: 520, blocked: 6 }, // PR
  { latitude: -27.5954, longitude: -48.5480, name: 'Santa Catarina', normal: 378, blocked: 4 }, // SC
  { latitude: -30.0346, longitude: -51.2177, name: 'Rio Grande do Sul', normal: 416, blocked: 1 }, // RS
  { latitude: -12.9714, longitude: -38.5014, name: 'Bahia', normal: 543, blocked: 8 }, // BA
  { latitude: -8.0476, longitude: -34.8770, name: 'Pernambuco', normal: 384, blocked: 0 }, // PE
  { latitude: -3.7172, longitude: -38.5433, name: 'Ceará', normal: 474, blocked: 2 }, // CE
  { latitude: -3.1190, longitude: -60.0217, name: 'Amazonas', normal: 274, blocked: 8 }, // AM
  { latitude: -16.6799, longitude: -49.2550, name: 'Goiás', normal: 389, blocked: 6 }, // GO
  { latitude: -20.4428, longitude: -54.6464, name: 'Mato Grosso do Sul', normal: 254, blocked: 5 }, // MS
  { latitude: -2.5307, longitude: -44.3068, name: 'Maranhão', normal: 241, blocked: 0 }, // MA
  { latitude: -5.7945, longitude: -35.2120, name: 'Rio Grande do Norte', normal: 287, blocked: 3 }, // RN
]

export const totalEvents = data.reduce((sum, d) => sum + d.normal, 0) / data.length

export const generateStyle = (
  backgroundColor: string,
  countryFillColor: string,
  countryBoundaryColor: string,
  coastlineColor: string
): MapLibreStyleSpecs => ({
  zoom: 10000,
  name: 'MapLibre-Unovis',
  sources: {
    maplibre: {
      url: 'https://demotiles.maplibre.org/tiles/tiles.json',
      type: 'vector',
    },
  },
  glyphs: 'https://demotiles.maplibre.org/font/{fontstack}/{range}.pbf',
  layers: [{
    id: 'background',
    type: 'background',
    paint: {
      'background-color': backgroundColor,
    },
    layout: {
      visibility: 'visible',
    },
    maxzoom: 24,
  },
  {
    id: 'coastline',
    type: 'line',
    paint: {
      'line-blur': 0.5,
      'line-color': coastlineColor,
      'line-width': {
        type: 'interval',
        stops: [
          [0, 2],
          [6, 6],
          [14, 9],
          [22, 18],
        ],
      },
    },
    filter: ['all'],
    layout: {
      'line-cap': 'round',
      'line-join': 'round',
      visibility: 'visible',
    },
    source: 'maplibre',
    maxzoom: 24,
    minzoom: 0,
    'source-layer': 'countries',
  },
  {
    id: 'countries-fill',
    type: 'fill',
    paint: {
      'fill-color': countryFillColor,
    },
    filter: ['all'],
    layout: {
      visibility: 'visible',
    },
    source: 'maplibre',
    maxzoom: 24,
    'source-layer': 'countries',
  },
  {
    id: 'countries-boundary',
    type: 'line',
    paint: {
      'line-color': countryBoundaryColor,
      'line-width': {
        type: 'interval',
        stops: [[1, 1], [6, 2], [14, 6],
          [22, 12],
        ],
      },
      'line-opacity': {
        type: 'interval',
        stops: [
          [3, 0.5],
          [6, 1],
        ],
      },
    },
    layout: {
      'line-cap': 'round',
      'line-join': 'round',
      visibility: 'visible',
    },
    source: 'maplibre',
    maxzoom: 24,
    'source-layer': 'countries',
  },
  {
    id: 'countries-label',
    type: 'symbol',
    paint: {
      'text-color': 'rgba(177, 177, 177, 1)',
      'text-halo-blur': {
        type: 'interval',
        stops: [
          [2, 0.2],
          [6, 0],
        ],
      },
      'text-halo-color': 'rgba(255, 255, 255, 1)',
      'text-halo-width': {
        type: 'interval',
        stops: [
          [2, 1],
          [6, 1.6],
        ],
      },
    },
    filter: ['all'],
    layout: {
      'text-font': [
        'Open Sans Semibold',
      ],
      'text-size': {
        type: 'interval',
        stops: [
          [2, 10],
          [4, 12],
          [6, 16],
        ],
      },
      'text-field': {
        type: 'interval',
        stops: [
          [4, '{NAME}'],
        ],
      },
      visibility: 'visible',
      'text-max-width': 10,
      'text-transform': {
        type: 'interval',
        stops: [
          [0, 'uppercase'],
          [2, 'none'],
        ],
      },
    },
    source: 'maplibre',
    maxzoom: 24,
    minzoom: 2,
    'source-layer': 'centroids',
  },
  ],
  bearing: 0,
  version: 8,
  metadata: {
    'maptiler:copyright': '',
    'openmaptiles:version': '',
  },
})

export const mapStyleLight = generateStyle('#DFE5EB', '#FEFEFE', '#DFE5EB', '#D3D8DE')

export const mapStyleDark = generateStyle('#292b34', '#5b5f6d', '#2a2a2a', '#2a2a2a')