import type { FetchContext } from 'ofetch'

export default defineNuxtPlugin((nuxtApp) => {
	const { session } = useUserSession()
	const config = useRuntimeConfig()
	const api = $fetch.create({
		baseURL: config.public.apiBaseUrl,
		onRequest(context: FetchContext): Promise<void> | void {
			if (session.value?.user) {
				const headers = context.options.headers ||= {}
				if (Array.isArray(headers)) {
					headers.push(['Authorization', `Bearer ${session.value?.token}`])
				} else if (headers instanceof Headers) {
					headers.set('Authorization', `Bearer ${session.value?.token}`)
				} else {
					headers.Authorization = `Bearer ${session.value?.token}`
				}
			} else {
				nuxtApp.runWithContext(() => navigateTo('/login'))
			}
		},
		async onResponseError({ response }) {
			if (response.status === 401) {
				await nuxtApp.runWithContext(() => navigateTo('/login'))
			}
		}
	})

	// expose to useNuxtApp().$api
	return {
		provide: {
			api
		}
	}
})
