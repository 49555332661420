<script setup lang="ts">
const colorMode = useColorMode()

const color = computed(() => colorMode.value === 'dark' ? '#111827' : 'white')

useHead({
	meta: [
		{ charset: 'utf-8' },
		{ name: 'viewport', content: 'width=device-width, initial-scale=1' },
		{ key: 'theme-color', name: 'theme-color', content: color }
	],
	link: [
		{ rel: 'icon', href: '/favicon.svg' }
	],
	htmlAttrs: {
		lang: 'en'
	}
})

const title = 'ConsigPro - Dashboard'
const description = ''

useSeoMeta({
	title,
	description,
	ogTitle: title,
	ogDescription: description,
	ogImage: 'https://dashboard-template.nuxt.dev/social-card.png',
	twitterImage: 'https://dashboard-template.nuxt.dev/social-card.png',
	twitterCard: 'summary_large_image'
})

const loginError = useState('loginError')
watch(loginError, (newVal) => {
	console.log('loginError changed:', newVal)
})
</script>

<template>
	<div>
		<NuxtLoadingIndicator color="rgb(var(--color-primary-DEFAULT))" />

		<NuxtLayout>
			<NuxtPage />
			<UModals />
		</NuxtLayout>
		<UNotifications>
			<template #title="{ title }">
				<span v-html="title" />
			</template>

			<template #description="{ description }">
				<span v-html="description" />
			</template>
		</UNotifications>
	</div>
</template>

<style>
.light {
  @apply bg-gray-100 text-gray-900;
}
.dark {
  @apply bg-gray-950 text-gray-50;
}

.page-enter-active,
.page-leave-active {
  transition: all 0.4s;
}
.page-enter-from,
.page-leave-to {
  opacity: 0;
  filter: blur(1rem);
}

.layout-enter-active,
.layout-leave-active {
  transition: all 0.4s;
}
.layout-enter-from,
.layout-leave-to {
  filter: grayscale(1);
}

/* Hide the up and down arrows from number input fields globally */
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}
</style>
