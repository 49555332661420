import { ROLES } from '~/utils/roles'

/**
 * Composable for handling user authorization and role checking
 * @returns {Object} An object containing authorization helper functions
 */
export const useAuthResolver = () => {
	const { user } = useUserSession()

	/**
   * Checks if the user has one of the specified roles
   * @param {...(keyof typeof ROLES)} roles - The roles to check
   * @returns {boolean} True if the user has one of the specified roles, false otherwise
   */
	const hasRole = (...roles: (keyof typeof ROLES)[]) => {
		if (!user.value || !user.value.nivelAcesso) return false

		return roles.some(role => ROLES[role] === user.value!.nivelAcesso)
	}

	/**
   * Checks if the user has one of the specified access levels
   * @param {...number} niveis - The access levels to check
   * @returns {boolean} True if the user has one of the specified access levels, false otherwise
   */
	const hasNivelAcesso = (...niveis: number[]) => {
		if (!user.value || !user.value.nivelAcesso) return false

		return niveis.includes(user.value.nivelAcesso)
	}

	/**
   * Allows access for any access level
   * @returns {boolean} Always returns true
   */
	const allowAnyAccess = () => true

	return {
		hasRole,
		hasNivelAcesso,
		allowAnyAccess
	}
}
